import React from "react";
import chevronDown from "../img/chevron.svg";

export default function FaqComponent() {
    function chevronClicked(q) {
        console.log(q);
        document.getElementById(q).checked =
            !document.getElementById(q).checked;
        document.getElementById(`${q}-chevron`).classList.toggle("chevron-up");
    }

    const questions = {
        "What is pdf2chat?":
            "pdf2chat is an easy-to-use chatbot builder, which can create ChatGPT-like chatbots from your own data.",
        "What data formats can I upload?":
            "Currently you can upload a .pdf file or paste some text.",
        "How much does it cost?":
            "We have 3 plans, ranging from $7 to $19, take a look at our pricing page for more details.",
        "How can I contact customer support?":
            "You can email us at support@pdf2chat.com",
        "How can pdf2chat help me?":
            "It can summarize anything you need to read, or answer questions from various material if you don't have time to read it yourself.",
        "Is pdf2chat safe to use?":
            "Yes, your data is hosted on secure servers, and will never be used for anything other than answering questions.",
    };

    return (
        <div className="max-w-screen-xl ml-auto mr-auto mt-36 max-[700px]:px-5">
            <div style={{ border: "none" }} className="faq-header">
                <h3 className="mb-12 font-bold text-6xl text-center text-gray-900 max-[700px]:text-5xl">
                    Frequently asked questions
                </h3>
            </div>
            <div className="faq-content">
                {Object.keys(questions).map((key, idx) => (
                    <div
                        className="faq-question"
                        onClick={(e) => chevronClicked("q" + idx)}
                        key={idx + 1}
                    >
                        <input
                            id={"q" + idx}
                            type="checkbox"
                            className="panel"
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <label className="panel-title font-medium ">
                                {key}
                            </label>
                            <img
                                src={chevronDown}
                                alt="Open answer"
                                id={"q" + idx + "-chevron"}
                                className="chevron-down"
                            />
                        </div>
                        <div className="panel-content">{questions[key]}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}
