export default function Reset() {
    return (
        <div
            style={{ marginTop: "5rem" }}
            className="log-sign-forgot-container"
        >
            <div className="login-cont">
                <div className="titles-log-sign">
                    <h1
                        style={{
                            color: "black",
                            opacity: "0.9",
                            fontSize: "35px",
                            fontWeight: "500",
                        }}
                    >
                        Reset password
                    </h1>
                    <p style={{ opacity: "0.6", fontSize: "0.95rem" }}>
                        Enter you new password
                    </p>
                </div>

                <form style={{ width: "100%" }} /*onSubmit={submitForm}*/>
                    <div style={{ width: "100%" }}>
                        <div
                            style={{
                                margin: "auto",
                                marginTop: "2rem",
                                width: "100%",
                                marginBottom: "2.5rem",
                            }}
                            className="input-group mb-3"
                        >
                            <p
                                htmlhtmlFor="exampleInputEmail1"
                                style={{
                                    fontWeight: "500",
                                    opacity: "0.9",
                                }}
                            >
                                New password
                            </p>
                            <input
                                style={{
                                    height: "3rem",
                                    boxShadow: "none",
                                    borderRadius: "0.5rem",
                                    margin: "auto",
                                    width: "100%",
                                    opacity: 0.5,
                                }}
                                type="password"
                                className="form-control"
                                /*onChange={(e) => setPassword(e.target.value)}*/
                                placeholder="Password"
                                aria-label="Password"
                                aria-describedby="basic-addon1"
                            />
                        </div>

                        <div
                            style={{
                                margin: "auto",
                                marginTop: "2rem",
                                width: "100%",
                                marginBottom: "2.5rem",
                            }}
                            className="input-group mb-3"
                        >
                            <p
                                htmlhtmlFor="exampleInputEmail1"
                                style={{
                                    fontWeight: "500",
                                    opacity: "0.9",
                                }}
                            >
                                Confirm password
                            </p>
                            <input
                                style={{
                                    height: "3rem",
                                    boxShadow: "none",
                                    borderRadius: "0.5rem",
                                    margin: "auto",
                                    width: "100%",
                                    opacity: 0.5,
                                }}
                                type="password"
                                className="form-control"
                                /*onChange={(e) => setPassword(e.target.value)}*/
                                placeholder="Password"
                                aria-label="Password"
                                aria-describedby="basic-addon1"
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            width: "100%",
                            alignItems: "center",
                            marginTop: "1rem",
                        }}
                    >
                        <div style={{ width: "100%", margin: "auto" }}>
                            <button
                                className="button-41"
                                style={{
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                                aria-label="Log In"
                            >
                                Log in
                            </button>
                        </div>
                    </div>
                </form>

                <div
                    style={{
                        display: "flex",
                        gap: "0.5rem",
                        marginBottom: "20px",
                        marginTop: "2rem",
                    }}
                >
                    <div>
                        <hr></hr>
                    </div>
                    <p style={{ fontSize: "14px", opacity: "0.7" }}>
                        Have an account?
                    </p>{" "}
                    <a href="/signup">
                        <p
                            style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                opacity: "0.9",
                                color: "#337ab7",
                            }}
                        >
                            Sign Up
                        </p>
                    </a>
                </div>
            </div>
        </div>
    );
}
