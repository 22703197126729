export default function daysAgo(ago) {
    let today = new Date();
    let sometime = new Date(ago);
    let msInDay = 24 * 60 * 60 * 1000;

    today.setHours(0, 0, 0, 0);
    sometime.setHours(0, 0, 0, 0);

    return (+today - +sometime) / msInDay;
}
