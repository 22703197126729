export default function Footer() {
    return (
        <section>
            <footer className="bg-zinc-900 m-0 pt-10 pb-0 max-[700px]:px-5">
                <div className="mx-auto w-8/12 m-0 lg:p-10 md:p-10 sm:p-10 max-[1200px]:w-10/12">
                    <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <div className="md:flex md:justify-between m-0 lg:p-10 md:p-10 sm:p-10 max-[700px]:pb-10">
                        <div className="mb-6 md:mb-0 max-[700px]:hidden">
                            <a
                                href="https://pdf2chat.com/"
                                className="flex items-center mb-5"
                            >
                                <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white text-zinc-200">
                                    pdf2chat
                                </span>
                            </a>
                            <div className="text-white w-9/12 mb-5">
                                <p className="text-gray-400">
                                    pdf2chat is a service for turning documents
                                    into chatbots.
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 ">
                            <div>
                                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white text-zinc-200">
                                    Resources
                                </h2>
                                <ul className="text-gray-600 dark:text-gray-400 text-white">
                                    <li>
                                        <a
                                            href="/faq"
                                            className="hover:underline text-zinc-400 "
                                        >
                                            FAQ
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase text-zinc-200">
                                    Follow us
                                </h2>
                                <ul className="text-gray-600 dark:text-gray-400">
                                    <li className="mb-4">
                                        <a
                                            href="https://twitter.com/pdf2chat"
                                            className="hover:underline  text-zinc-400"
                                        >
                                            Twitter
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://discord.gg/4eeurUVvTy"
                                            className="hover:underline text-zinc-400"
                                        >
                                            TikTok
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase  text-zinc-200">
                                    Legal
                                </h2>
                                <ul className="text-gray-600 dark:text-gray-400">
                                    <li className="mb-4">
                                        <a
                                            href="/privacy_policy"
                                            className="hover:underline text-zinc-400"
                                        >
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="/tos"
                                            className="hover:underline text-zinc-400"
                                        >
                                            Terms &amp; Conditions
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </section>
    );
}
