import "../views/chatbots.css";
import { useState, useEffect } from "react";
import daysAgo from "../scripts/daysAgo";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function Chatbots() {
    const [chatbots, setChatbots] = useState([]);
    const [limits, setLimits] = useState({});
    const [loading, isLoading] = useState(true);

    const navigate = useNavigate();

    async function getChatbots() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/chatbots`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        );
        let json = await response.json();
        if (json.error) {
            return toast.error(json.error);
        }
        setChatbots(json.chatbots);
        setLimits(json.limits);
        isLoading(false);
    }

    function normalize(number) {
        let normalized = "N/A";
        if (number) normalized = number.toLocaleString();
        if (number === 0) normalized = "0";
        return normalized;
    }

    useEffect(() => {
        getChatbots();
    }, []);

    if (!loading) {
        return (
            <div className="chat-page-wrap" style={{ marginTop: "10rem" }}>
                <div className="flex flex-row justify-between max-[700px]:flex-col max-[700px]:gap-10 max-[700px]:text-center items-center">
                    <h1 className="text-5xl font-bold leading-tight tracking-tight text-gray-900 md:text-5xl dark:text-white">
                        Your chatbots ({chatbots.length}/{limits.maxChatbots})
                    </h1>
                    <button
                        onClick={(e) => navigate("/create")}
                        className="h-3/6 w-40 font-semibold text-base text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 hover:bg-blue-700 dark:focus:ring-primary-800 bg-blue-600 max-[700px]:m-auto max-[700px]:w-3/6"
                    >
                        Create new
                    </button>
                </div>

                <div style={{ marginTop: "2rem" }} className="chatbots">
                   

                    {/*                      PC TABLE                         */}

                    <div className="relative overflow-x-auto sm:rounded-lg border-solid border-2 border-neutral-200 max-[1000px]:hidden">
                        <table className="w-full text-base text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Characters
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Messages sent
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Created
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="text-xl font-semibold">
                                {chatbots.map((chatbot) => {
                                    return (
                                        <tr
                                            key={chatbot._id}
                                            className="border-solid border-t-2 border-neutral-200"
                                            onClick={(e) =>
                                                navigate("/chat/" + chatbot._id)
                                            }
                                            style={{ cursor: "pointer" }}
                                        >
                                            <th
                                                scope="row"
                                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                            >
                                                {chatbot.name}
                                            </th>
                                            <td className="px-6 py-4">
                                                {normalize(chatbot.characters)}
                                            </td>
                                            <td className="px-6 py-4">
                                                {normalize(
                                                    chatbot.sentMessages
                                                )}
                                            </td>
                                            <td className="px-6 py-4">
                                                {daysAgo(chatbot.createdAt)}{" "}
                                                days ago
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    {/*                       MOBILE CARD                         */}
                    <div className="hidden max-[1000px]:inline-block w-full mt-10">
                        {chatbots.map((chatbot) => {
                            return (
                                <>
                                    <div
                                        key={chatbot._id}
                                        className="border-solid border-2 border-neutral-200 w-full rounded-2xl mb-10"
                                        onClick={(e) =>
                                            navigate("/chat/" + chatbot._id)
                                        }
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div className="text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 px-5 pt-5 rounded-2xl">
                                            <div className="flex gap-10 justify-between mb-5">
                                                <p className="font-bold">
                                                    Name:
                                                </p>
                                                <p className="font-bold text-gray-500">
                                                    {chatbot.name}
                                                </p>
                                            </div>
                                            <div className="flex gap-10 justify-between mb-5">
                                                <p className="font-bold">
                                                    {" "}
                                                    Characters:
                                                </p>
                                                <p className="font-bold text-gray-500">
                                                    {normalize(
                                                        chatbot.characters
                                                    )}
                                                </p>
                                            </div>
                                            <div className="flex gap-10 justify-between mb-5">
                                                <p className="font-bold">
                                                    Messages sent:
                                                </p>
                                                <p className="font-bold text-gray-500">
                                                    {" "}
                                                    {normalize(
                                                        chatbot.sentMessages
                                                    )}
                                                </p>
                                            </div>
                                            <div className="flex gap-10 justify-between pb-5 ">
                                                <p className="font-bold">
                                                    Created:
                                                </p>
                                                <p className="font-bold text-gray-500">
                                                    {" "}
                                                    {daysAgo(
                                                        chatbot.createdAt
                                                    )}{" "}
                                                    days ago
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
