import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function Verify() {
    const navigate = useNavigate();
    const { token } = useParams();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/verify_email/${token}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token,
            }),
            credentials: "include",
        }).then(async function (response) {
            const json = await response.json();
            if (json.error) {
                return toast.error(json.error);
            }
            toast.success("Email verified");
            navigate(`/`);
        });
    }, []);

    return (<div>dddddd</div>)
}
