import "./../views/landing.css";
import ChatComponent from "./ChatComponent";
import React from "react";
import FaqComponent from "./FaqComponent";
import PricingComponent from "./PricingComponent";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

import askChatbot from "../img/ask.png";
import createChatbot from "../img/create.png";

export default function LandingPage() {
    const authenticated = document.cookie.includes("connect.sid");
    const createLink = authenticated ? "/chatbots" : "/signup";
    const navigate = useNavigate();

    const suggestedQuestions = [
        "What is the text about?",
        "What's the main point of the text?",
        "Who is Marcus Aurelius?",
    ];

    return (
        <div className="absolute w-full h-full">
            <div>
                {/*           Hero-Header          */}
                <div className="max-w-screen-xl ml-auto mr-auto text-center max-[700px]:px-5">
                    <h2 className="text-7xl mt-52 max-[700px]:mt-36 font-bold max-w-4xl text-center ml-auto mr-auto text-gray-900 max-[700px]:text-6xl">
                        Create a ChatGPT from your documents
                    </h2>
                    <p className="mt-10 max-w-3xl ml-auto mr-auto text-2xl font-medium opacity-70 text-gray-900">
                        Easily create intelligent AI chatbots which can answer
                        complex questions from your own{" "}
                        <span className="font-bold">PDFs or text</span>.
                    </p>
                </div>
                <div className="mt-20 flex flex-col items-center">
                    <button
                        aria-label="Create a chatbot today!"
                        onClick={() => navigate(createLink)}
                        className="p-10 text-xl w-80 text-center text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 flex justify-between items-center"
                    >
                        <span>Create a chatbot today!</span>
                        <i>
                            {" "}
                            <svg
                                className="w-6 h-6 ml-0"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                ></path>
                            </svg>
                        </i>
                    </button>
                </div>

                {/*           Hero-Chat-Demo          */}
                <div className="mt-36 flex flex-col items-center">
                    <p className="text-gray-700 uppercase font-semibold dark:text-gray-400">
                        Or try it for free
                    </p>
                </div>
                <div className="pb-20">
                    <ChatComponent
                        _id={process.env.REACT_APP_DEMO_CHATBOT_ID}
                        demo={true}
                        name={process.env.REACT_APP_DEMO_CHATBOT_NAME}
                        height="50rem"
                        questions={suggestedQuestions}
                        hideMessagesLeft={true}
                        marginTop={"1rem"}
                    />
                </div>
            </div>
            {/*           About Product           */}
            <div className="text-white lg:p-10 bg-zinc-900 ">
                <div className="flex max-w-screen-xl ml-auto mr-auto justify-between gap-6 items-center pt-28 max-[1025px]:flex-col max-[1025px]:pt-28 ">
                    <div className="justify-items-center items-center w-3/6 mr-3 max-[1025px]:w-11/12 max-[1025px]:items-center max-[1025px]:pb-8 max-[1025px]:m-0">
                        <h3 className="font-bold text-5xl text-left max-[1025px]:text-center">
                            Create a chatbot in 5 minutes.
                        </h3>
                        <p className="mt-4 text-2xl text-left max-[1025px]:text-center">
                            Add your .pdf file and have a chatbot ready to
                            answer all your questions in just a few minutes!
                        </p>
                    </div>
                    <div className="w-3/6 bg-sky-50 h-128 rounded-xl max-[1025px]:w-11/12 max-[700px]:h-96 max-[1200px]:w-8/12">
                        <img
                            className="rounded-lg w-full h-full object-cover"
                            src={createChatbot}
                            alt="Creating a chatbot."
                        />
                    </div>
                </div>

                <div className="flex max-w-screen-xl ml-auto mr-auto justify-between gap-6 items-center mt-48 pb-28 max-[1025px]:flex-col max-[1025px]:pt-28 max-[1025px]:pb-28 max-[1025px]:pt-0 max-[700px]:-mt-10">
                    <div className="w-3/6 bg-violet-50 h-128 rounded-xl max-[1025px]:order-2 max-[1025px]:w-11/12 max-[700px]:h-96 max-[1200px]:w-9/12">
                        <img
                            className="rounded-lg w-full h-full object-cover"
                            src={askChatbot}
                            alt="Asking a chatbot."
                        />
                    </div>
                    <div className="justify-items-center items-center w-3/6 ml-3 max-[1025px]:order-1 max-[1025px]:w-11/12 max-[1025px]:pb-8 max-[1025px]:m-0">
                        <h3 className="font-bold text-5xl text-left max-[1025px]:text-center">
                            Ask your chatbot anything.
                        </h3>
                        <p className="mt-5 text-2xl text-left max-[1025px]:text-center">
                            The AI chatbot, similar to ChatGPT, will answer any
                            question you may have about the text provided.
                        </p>
                    </div>
                </div>
            </div>
            {/*           FAQ           */}
            <FaqComponent />
            {/*           Pricing           */}

            <div className="ml-auto mr-auto mt-48 bg-zinc-800 ">
                <PricingComponent />

                {/*       TRY IT OUT NOW NOW            */}
                <section className="pt-20 pb-20 bg-zinc-900 ">
                    <div
                        id="try-it-out"
                        className="w-3/6 mr-auto ml-auto rounded-2xl bg-gradient-to-r from-blue-600 to-purple-600 pt-10 pb-10 max-[1200px]:w-9/12"
                    >
                        <div className="pl-3">
                            <div className="mx-6">
                                <p className="text-6xl font-bold text-white mb-7">
                                    Create your own chatbot.
                                </p>
                                <a href="/signup" className="get-started flex">
                                    <p>Get started today!</p>
                                    <svg
                                        className="w-4 h-4 ml-2"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M14 5l7 7m0 0l-7 7m7-7H3"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                            <div className="-mt-4">
                                <div className="flex items-center gap-2 text-white">
                                    <svg
                                        className="ml-6 mt-10"
                                        width="20px"
                                        height="20px"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M20 6L9 17L4 12"
                                            stroke="white"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                    <p className="mt-10">
                                        No credit card required.
                                    </p>
                                </div>
                                <div className="flex items-center gap-2 text-white">
                                    <svg
                                        className="ml-6 mt-2"
                                        width="20px"
                                        height="20px"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M20 6L9 17L4 12"
                                            stroke="white"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                    <p className="mt-2">Takes 5 minutes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </div>
    );
}
