import React from "react";
import { Toaster } from "react-hot-toast";
import Header from "./Header";

export default function All(props) {
    return (
        <div>
            {!props.noHeader && <Header />}
            <Toaster />
        </div>
    );
}
