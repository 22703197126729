import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import "./views/index.css";
import ChatPage from "./components/ChatPage.jsx";
import reportWebVitals from "./reportWebVitals";
import LandingPage from "./components/LandingPage";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import Forgot from "./components/Forgot";
import Reset from "./components/Reset";
import Chatbots from "./components/Chatbots";
import All from "./components/All";
import Create from "./components/Create";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import FaqPage from "./components/FaqPage";
import PricingPage from "./components/PricingPage";
import Account from "./components/Account";
import Portal from "./components/Portal";
import Verify from "./components/Verify";
import VerifyPage from "./components/VerifyPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <Routes>
            <Route
                exact
                path="/"
                element={
                    <>
                        <All />
                        <LandingPage />
                    </>
                }
            />
            <Route
                exact
                path="/verify_email"
                element={
                    <>
                        <All />
                        <VerifyPage />
                    </>
                }
            />
            <Route
                exact
                path="/account"
                element={
                    <>
                        <All />
                        <Account />
                    </>
                }
            />

            <Route
                exact
                path="/faq"
                element={
                    <>
                        <All />
                        <FaqPage />
                    </>
                }
            />

            <Route
                exact
                path="/pricing"
                element={
                    <>
                        <All />
                        <PricingPage />
                    </>
                }
            />
            <Route
                exact
                path="/chatbots"
                element={
                    <>
                        <All />
                        <Chatbots />
                    </>
                }
            />

            <Route
                exact
                path="/chat/:id"
                element={
                    <>
                        <All />
                        <ChatPage />
                    </>
                }
            />

            <Route
                exact
                path="/login"
                element={
                    <>
                        <All />
                        <Login />
                    </>
                }
            />

            <Route
                exact
                path="/signup"
                element={
                    <>
                        <All />
                        <SignUp />
                    </>
                }
            />

            <Route
                exact
                path="/forgot"
                element={
                    <>
                        <All />
                        <Forgot />
                    </>
                }
            />
            <Route
                exact
                path="/reset"
                element={
                    <>
                        <All />
                        <Reset />
                    </>
                }
            />

            <Route
                exact
                path="/create"
                element={
                    <>
                        <All />
                        <Create />
                    </>
                }
            />

            <Route
                exact
                path="/tos"
                element={
                    <>
                        <All />
                        <TermsOfService />
                    </>
                }
            />

            <Route
                exact
                path="/privacy_policy"
                element={
                    <>
                        <All />
                        <PrivacyPolicy />
                    </>
                }
            />

            <Route
                exact
                path="/portal"
                element={
                    <>
                        <All />
                        <Portal />
                    </>
                }
            />

            <Route
                exact
                path="/verify/:token"
                element={
                    <>
                        <All noHeader={true} />
                        <Verify />
                    </>
                }
            />
        </Routes>
    </BrowserRouter>
);

reportWebVitals();
