import React from "react";
import PricingComponent from "./PricingComponent";

export default function PricingPage() {
    return (
        <div>
            <PricingComponent />
        </div>
    );
}
 