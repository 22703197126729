import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import getCharacters from "../scripts/getCharacters.js";
import "../views/create.css";

export default function Create() {
    const [text, setText] = useState("");
    const [name, setName] = useState(null);
    const [characters, setCharacters] = useState(0);
    const [type, setType] = useState("file");
    const [loading, isLoading] = useState(true);
    const [colorClass, setColorClass] = useState("text-lime-500");
    const [limits, setLimits] = useState({
        charactersPerChatbot: 1000000,
    });
    const [loadingCharacters, setLoadingCharacters] = useState(true);
    const [overLimit, setOverLimit] = useState(false);
    const [error, setError] = useState("");
    const [uploading, setUploading] = useState(false);
    const navigate = useNavigate();

    const [files, setFiles] = useState([]);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            "application/pdf": [],
        },
        maxFiles: 1,
    });

    const removeFiles = () => {
        setFiles([]);
    };

    useEffect(() => {
        setOverLimit(false);
        setError(0);
        setFiles(acceptedFiles);
    }, [acceptedFiles]);

    async function getLimits() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/limits`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        );
        let json = await response.json();
        if (json.error) {
            return toast.error(json.error);
        }
        setLimits(json);
        isLoading(false);
    }

    useEffect(() => {
        getLimits();
    }, []);

    useEffect(() => {
        if (type === "text") setFiles([]);
        if (type === "file") setText("");
    }, [type]);

    useEffect(() => {
        if (characters > limits.charactersPerChatbot) {
            setColorClass("text-red-500");
        } else {
            setColorClass("text-lime-500");
        }
        isLoading(false);
    }, [characters]);

    useEffect(() => {
        if (files.length < 1) {
            setCharacters(0);
            return;
        }
        async function updateChars() {
            setLoadingCharacters(true);
            let { characters, fullText } = await getCharacters(
                acceptedFiles[0]
            );
            setText(fullText);
            if (characters > limits.charactersPerChatbot) {
                let error = `Your PDF characters (${characters}) exceeds limit (${
                    limits.charactersPerChatbot
                }) by ${characters - limits.charactersPerChatbot}`;
                toast.error(error);
                setError(error);
                setOverLimit(true);
            }
            setCharacters(characters);
            setLoadingCharacters(false);
        }
        updateChars();
    }, [files]);

    useEffect(() => {
        if (files.length > 0) return;
        setLoadingCharacters(true);
        let newCharacters = text.split("").length;
        if (newCharacters > limits.charactersPerChatbot) {
            let error = `Your PDF characters (${newCharacters}) exceeds limit (${
                limits.charactersPerChatbot
            }) by ${newCharacters - limits.charactersPerChatbot}`;
            toast.error(error);
            setError(error);
            setOverLimit(true);
        }
        setCharacters(newCharacters);
        setLoadingCharacters(false);
    }, [text]);

    async function create(e) {
        e.preventDefault();
        setUploading(true);
        if (overLimit) return toast.error(error);
        if (!name) return toast.error("Please enter the name of the chatbot");
        if (!characters) return toast.error("Please upload a file");

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/chatbots`,
            {
                method: "POST",
                body: JSON.stringify({ name, text }),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        );
        const json = await response.json();
        if (json.error) {
            return toast.error(json.error);
        }
        toast.success("Chatbot created");
        navigate(`/chat/${json._id}`);
    }

    return (
        !loading && (
            <section className="bg-gray-50 dark:bg-gray-900 p-full w-full h-full">
                <div className="bg-gray-50 absolute w-full h-full">
                    <div className="rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 div-create-wrap">
                        <h1 className="text-5xl font-bold leading-tight tracking-tight text-gray-900 md:text-4xl dark:text-white max-[700px]:text-center">
                            Create a new chatbot
                        </h1>
                        <div>
                            <label
                                htmlFor="name"
                                className="text-base block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Name
                            </label>
                            <input
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                id="name"
                                className="text-base bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="My Chatbot"
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="type"
                                className="text-base block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Text type
                            </label>
                            <select
                                onChange={(e) => setType(e.target.value)}
                                id="type"
                                className="text-base bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option value="file">File</option>
                                <option value="text">Text</option>
                            </select>
                        </div>

                        {type === "file" && files.length < 1 && (
                            <div
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                style={{
                                    padding: "7rem",
                                    textAlign: "center",
                                }}
                            >
                                {/* Provide a drop zone and an alternative button inside it to upload files. */}
                                <div
                                    {...getRootProps({ className: "dropzone" })}
                                    className="text-base"
                                >
                                    <p>Drag and drop files here</p>

                                    <button
                                        style={{
                                            textDecoration: "underline",
                                        }}
                                    >
                                        Or select files to upload
                                    </button>

                                    <input
                                        {...getInputProps()}
                                        style={{ display: "none" }}
                                    />
                                </div>
                            </div>
                        )}
                        {type === "text" && (
                            <textarea
                                id="message"
                                rows="12"
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Paste your text here..."
                                cols="90"
                                onChange={(e) => setText(e.target.value)}
                                value={text}
                            ></textarea>
                        )}
                        {files.length > 0 && (
                            <div className="flex gap-x-2 flex-row justify-center">
                                <h1 className="text-xl font-semibold text-center break-all ">
                                    {files[0]?.name || "Unknown"}
                                </h1>
                                <button
                                    className="text-xl font-bold text-center text-red-500"
                                    onClick={removeFiles}
                                >
                                    X
                                </button>
                            </div>
                        )}
                        {!loadingCharacters && (
                            <h1
 
                                className={
                                    "text-3xl font-semibold text-center " +
                                    colorClass
                                }
                            >
                                {characters.toLocaleString()}/
                                {limits.charactersPerChatbot.toLocaleString()}{" "}
                                characters
                            </h1>
                        )}
                        {loadingCharacters && (
                            <h1
                                className={
                                    "text-3xl font-semibold text-center text-stone-500"
                                }
                            >
                                <div className="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                /{limits.charactersPerChatbot.toLocaleString()}{" "}
                                characters
                            </h1>
                        )}

                        {!uploading && (
                            <button
                                onClick={create}
                                type="submit"
                                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 bg-blue-600 hover:bg-blue-700"
                            >
                                Create New
                            </button>
                        )}
                        {uploading && (
                            <>
                                <button
                                    type="submit"
                                    className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 bg-blue-700"
                                    disabled
                                >
                                    Uploading...
                                </button>
                                <p className="text-center -mt-2 mb-2 block font-medium text-gray-500 dark:text-white">
                                    Please wait until the upload finishes.
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </section>
        )
    );
}
