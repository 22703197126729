function getPageText(pageNum, PDFDocumentInstance) {
    return new Promise(function (resolve, reject) {
        PDFDocumentInstance.getPage(pageNum).then(function (pdfPage) {
            // The main trick to obtain the text of the PDF page, use the getTextContent method
            pdfPage.getTextContent().then(function (textContent) {
                var textItems = textContent.items;
                var finalString = "";

                // Concatenate the string of the item to the final string
                for (var i = 0; i < textItems.length; i++) {
                    var item = textItems[i];

                    finalString += item.str + " ";
                }

                // Solve promise with the text retrieven from the page
                resolve(finalString);
            });
        });
    });
}

export default async function getCharacters(file) {
    let url = URL.createObjectURL(file);
    const pdfJS = await import("pdfjs-dist/build/pdf");
    pdfJS.GlobalWorkerOptions.workerSrc =
        window.location.origin + "/pdf.worker.min.js";
    let doc = await pdfJS.getDocument(url).promise;
    let totalPages = doc._pdfInfo.numPages;
    let fullText = "";
    for (let i = 1; i <= totalPages; ++i) {
        let pageText = await getPageText(i, doc);
        fullText += pageText;
    }
    let characters = fullText.split("").length;
    return { fullText, characters };
}
