import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function Account() {
    const navigate = useNavigate();
    const [loading, isLoading] = useState(true);
    const [user, setUser] = useState({});
    const [plan, setPlan] = useState("Free");
    const [emailStatus, setEmailStatus] = useState("Not Verified");
    const [emailColor, setEmailColor] = useState("#f87171");

    async function getUser() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/user`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        );
        let json = await response.json();
        if (json.error) {
            return toast.error(json.error);
        }
        if (json.subscription.paid) {
            let priceId = json.subscription.price_id;
            if (priceId === process.env.REACT_APP_STARTER_PRICE_ID) {
                setPlan("Starter ($7/mo)");
            } else if (priceId === process.env.REACT_APP_PROFESSIONAL_PRICE_ID)
                setPlan("Professional ($19/mo)");
            else {
                setPlan("Custom");
            }
        }
        setUser(json);
        setEmailStatus(json.verifiedEmail ? "Verified" : "Not verified");
        setEmailColor(json.verifiedEmail ? "text-green-400" : "text-red-400");
        isLoading(false);
    }

    useEffect(() => {
        getUser();
    }, []);

    return (
        !loading && (
            <div className="mt-36 px-5 pb-10">
                <h1 className="mt-12 font-bold text-6xl text-center text-gray-900">
                    Account
                </h1>
                <h1 className="mt-6 font-semibold text-3xl text-center text-gray-900 break-words">
                    {user.username}
                </h1>
                <div className="acc-div-one">
                    <div>
                        <p className="mt-12 font-bold text-2xl text-center text-gray-900">
                            Your current plan
                        </p>
                        <p className="mt-6 mb-12 font-bold text-5xl text-center text-gray-900">
                            {plan}
                        </p>
                    </div>
                </div>
                <div className="acc-div-one">
                    <div>
                        <p className="mt-12 font-bold text-2xl text-center text-gray-900">
                            Email
                        </p>
                        <p
                            className={
                                "mt-6 mb-12 font-bold text-5xl text-center " +
                                emailColor
                            }
                        >
                            {emailStatus}
                        </p>
                    </div>
                </div>
                {plan !== "Free" && (
                    <div className="acc-div-one">
                        <div>
                            <p className="mt-12 font-bold text-2xl text-center text-gray-900">
                                Billing & Plans
                            </p>
                            <p className="mt-2 mx-10 text-xl text-center text-gray-900 font-medium">
                                Change your payment plans, cancel subscriptions
                                or edit your billing data.
                            </p>
                            <div className="flex justify-center mt-4 pb-8">
                                <button
                                    onClick={(e) => navigate("/portal")}
                                    className="text-2xl px-10 py-3 font-semibold text-white transition duration-300 bg-slate-900 rounded-md hover:bg-slate-800 ease"
                                >
                                    <span>Update billing</span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {/*
                <div className="flex justify-center mt-20 pb-8">
                    <button
                        onClick={() => logout()}
                        className="px-10 py-3 font-medium text-white transition duration-300 bg-slate-900 rounded-md hover:bg-slate-800 ease"
                    >
                        <span>Sign out</span>
                    </button>
                </div>
                */}
            </div>
        )
    );
}
