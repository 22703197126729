import React from "react";
import FaqComponent from "./FaqComponent";

export default function FaqPage() {
    return (
        <div>
            <FaqComponent />
        </div>
    );
}
