import toast from "react-hot-toast";
import React, { useEffect } from "react";

export default function Portal() {
    async function openPortal() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/portal`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        );
        let json = await response.json();
        if (json.error) {
            return toast.error(json.error);
        }
        if (json.url) {
            window.location.replace(json.url);
        }
    }
    useEffect(() => {
        console.log("aaa");
        openPortal();
    }, []);

    return <div></div>;
}
