function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true && str.startsWith("{") && str.endsWith("}");
}

export default isJson;
