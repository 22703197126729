import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function Forgot() {
    const [email, setEmail] = useState(false);
    const navigate = useNavigate();

    async function sendLink(e) {
        e.preventDefault();
        if (!email) toast.error("No email provided");

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/forgot_password`,
            {
                method: "POST",
                body: JSON.stringify({
                    username: email,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        );
        let json = await response.json();
        if (json.error) {
            return toast.error(json.error);
        }
        toast.success("Link to reset password sent to email");
        return navigate("/login");
    }
    return (
        <section className="bg-gray-50 dark:bg-gray-900 p-full w-full h-full">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
                <a
                    href="#"
                    className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
                >
                    pdf2chat
                </a>
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Reset password
                        </h1>
                        <form className="space-y-4 md:space-y-6" action="#">
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Your email
                                </label>
                                <input
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="name@company.com"
                                    required=""
                                />
                            </div>
                            <button
                                onClick={sendLink}
                                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 bg-blue-700"
                            >
                                Email me a link to reset it
                            </button>
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                Remembered your password?{" "}
                                <a
                                    href="/login"
                                    className="font-medium text-blue-700 hover:underline dark:text-primary-500"
                                >
                                    Login
                                </a>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}
