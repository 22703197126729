import React from "react";
import Modal from "react-modal";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "40rem",
        padding: "3rem",
    },
};

export default function ConfirmDelete(props) {
    const navigate = useNavigate();

    async function deleteChatbot(_id) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/chatbots`,
            {
                method: "DELETE",
                body: JSON.stringify({
                    _id: _id,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        );
        let json = await response.json();
        if (json.error) {
            return toast.error(json.error);
        }
        toast.success("Deleted chatbot.");
        return navigate("/chatbots");
    }

    return (
        <Modal
            isOpen={props.deleteOpen}
            onRequestClose={props.closeDelete}
            style={customStyles}
            contentLabel="Delete"
            id="modal-confirm"
        >
            <div>
                <h1 style={{ fontSize: "2rem" }}>
                    Are you sure you want to delete?
                </h1>
                <button
                    style={{
                        backgroundColor: "#ff244c",
                        color: "white",
                        fontSize: "1rem",
                        fontWeight: 500,
                        padding: "0.8rem",
                        borderRadius: "0.5rem",
                        marginTop: "2rem",
                        width: "13rem",
                        marginLeft: "auto",
                        display: "block",
                        marginRight: "0",
                    }}
                    onClick={(e) => deleteChatbot(props.chatbot)}
                >
                    I'm sure, delete.
                </button>
            </div>
        </Modal>
    );
}
