import React, { useState, useRef, useEffect } from "react";
import Settings from "./Settings";
import "../views/chatpage.css";
import useWebSocket from "react-use-websocket";
import isJson from "../scripts/isJson";

export default function ChatComponent(props) {
    const demo = props.demo;
    const id = props._id;
    const [suggestedLoaded, setSuggestedLoaded] = useState(false);
    const [questions, setQuestions] = useState([]);
    if (!suggestedLoaded && props.questions) {
        setQuestions(props.questions);
        setSuggestedLoaded(true);
    }
    const host = process.env.REACT_APP_WS_URL;
    const websocketProtocol =
        window.location.protocol === "https:" ? "wss" : "ws";
    // eslint-disable-next-line
    const [socketUrl, setSocketUrl] = useState(
        `${websocketProtocol}://${host}/chat?namespace=${id}`
    );
    const [messages, setMessages] = useState([
        { role: "assistant", content: "Hey, what can I help you with?" },
    ]);
    const [currentMessage, setCurrentMessage] = useState("");

    const [ready, setReady] = useState(false);
    if (demo && !ready) setReady(true);
    const [prompt, setPrompt] = useState(
        `You are AI Helper - a document I'm having a conversation with. You'll be given a text, and have to answer my questions according to said text. Do not ever mention the text in your answers. If a question is not in the text, say "I don't know".`
    );
    const [messagesLeft, setMessagesLeft] = useState(0);
    const [sources, setSources] = useState([]);
    const [name, setName] = useState(props.name || "A document.");
    const [error, setError] = useState(false);
    const bottom = useRef();

    const [settingsOpen, setSettingsOpen] = useState(false);

    function openSettings() {
        setSettingsOpen(true);
    }

    function closeSettings() {
        setSettingsOpen(false);
    }

    const { sendJsonMessage } = useWebSocket(socketUrl, {
        onMessage: receiveMessage,
    });

    function receiveMessage(msg) {
        let message = msg.data;
        let json = isJson(message);
        if (!json) {
            let newMessage = (messages[messages.length - 1].content += message);
            const newMessages = messages.map((c, i) => {
                if (i === messages.length - 1) {
                    let nc = {
                        ...c,
                    };
                    nc.content = newMessage;
                    return nc;
                } else {
                    return c;
                }
            });
            setMessages(newMessages);
        }
        if (json) {
            message = JSON.parse(message);
            switch (message.type) {
                case "ready":
                    setReady(message.data);
                    break;
                case "messagesLeft":
                    setMessagesLeft(message.data);
                    break;
                case "name":
                    if (demo) return;
                    setName(message.data);
                    break;
                case "sources":
                    setSources(message.data);
                    break;
                case "error":
                    setReady(false);
                    setError(message.data);
                    break;
                case "prompt":
                    setPrompt(message.data);
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        let chatWindow = document.getElementById("chat");
        if (!chatWindow) return;
        let xH = chatWindow.scrollHeight;
        chatWindow.scrollTo(0, xH);
    }, [messages]);

    function sendQuery(text = false) {
        let send = currentMessage;
        if (text) send = text;
        if (!send) return;

        console.log(send);

        let message = { query: send };

        sendJsonMessage(message);
        setSources([]);
        setMessages([
            ...messages,
            { role: "user", content: send },
            { role: "assistant", content: "" },
        ]);
        setCurrentMessage("");
    }

    return (
        <div>
            {/* Not the button, just the options */}
            {!demo && (
                <Settings
                    closeSettings={closeSettings}
                    settingsOpen={settingsOpen}
                    prompt={prompt}
                    setPrompt={setPrompt}
                    chatbot={id}
                />
            )}
            <div
                style={{ marginTop: props.marginTop || "" }}
                className="chat-section-wrap"
            >
                {error && !demo && (
                    <div>
                        <h1 style={{ fontSize: "4rem" }}>{error}</h1>
                    </div>
                )}
                {!ready && !error && (
                    <div>
                        <h1
                            style={{
                                fontSize: "4rem",
                                fontWeight: "500",
                                color: "#111827",
                            }}
                        >
                            Loading chatbot...
                        </h1>
                    </div>
                )}
                {ready && (
                    <>
                        <div className="chat-section-header">
                            <div>
                                <p
                                    style={{
                                        fontSize: "30px",
                                        fontWeight: "600",
                                        color: "#111827",
                                    }}
                                >
                                    {name}
                                </p>
                            </div>

                            {!demo && (
                                <div style={{ display: "flex", gap: "3rem" }}>
                                    <button
                                        className="button-chat-section"
                                        role="button"
                                        onClick={openSettings}
                                        aria-label="Open settings"
                                    >
                                        Settings
                                    </button>
                                </div>
                            )}
                        </div>
                        <div
                            className="chat-background"
                            style={{ height: props.height || "" }}
                        >
                            <div className="chat-section" id="chat">
                                <div className="messages">
                                    {messages.map((message, idx) => {
                                        if (message.role === "assistant") {
                                            return (
                                                <div
                                                    key={idx}
                                                    className="message ai-chat"
                                                >
                                                    <p
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {message.content}
                                                    </p>
                                                </div>
                                            );
                                        } else if (message.role === "user") {
                                            return (
                                                <div
                                                    key={idx}
                                                    className="message human-chat"
                                                >
                                                    <p
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {message.content}
                                                    </p>
                                                </div>
                                            );
                                        }
                                    })}
                                    <div ref={bottom}></div>
                                </div>
                                <div
                                    style={{
                                        marginBottom: "0.5rem",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    {!props.hideMessagesLeft && (
                                        <p
                                            style={{
                                                marginLeft: "2rem",
                                                fontSize: "0.85rem",
                                                color: "#7a7a7a",
                                                fontWeight: 450,
                                            }}
                                        >
                                            {messagesLeft} messages left
                                        </p>
                                    )}
                                    <div
                                        style={{
                                            marginLeft: "2rem",
                                            marginRight: "2rem",
                                        }}
                                        className="flex flex-row mb-8 max-[700px]:flex-col gap-8"
                                    >
                                        {questions.map((question) => {
                                            return (
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            "rgb(78, 137, 255)",
                                                        color: "white",
                                                        padding: "0.5rem",
                                                        borderRadius: "0.5rem",
                                                        fontSize: "0.9rem",
                                                        boxShadow:
                                                            "rgba(100, 100, 111, 0.3) 0px 7px 29px 0px",
                                                        cursor: "pointer",
                                                    }}
                                                    key={question}
                                                    onClick={(e) => {
                                                        setQuestions(
                                                            questions.filter(
                                                                function (
                                                                    item
                                                                ) {
                                                                    return (
                                                                        item !==
                                                                        question
                                                                    );
                                                                }
                                                            )
                                                        );
                                                        sendQuery(question);
                                                    }}
                                                >
                                                    {question}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    sendQuery();
                                }}
                            >
                                <div className="chat-input-wrap">
                                    <input
                                        type="text"
                                        value={currentMessage}
                                        onChange={(e) =>
                                            setCurrentMessage(e.target.value)
                                        }
                                        aria-labelledby="enter message"
                                    />
                                    <input type="submit" hidden />
                                    <button
                                        type="submit"
                                        className="button-chat-intput"
                                        role="button"
                                        aria-label="Send message"
                                    >
                                        <svg
                                            style={{
                                                transform: "rotate(90deg)",
                                            }}
                                            width="30px"
                                            height="30px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12.0005 18.9998V11.9998M12.292 19.0845L19.2704 21.4207C19.8173 21.6038 20.0908 21.6953 20.2594 21.6296C20.4059 21.5726 20.517 21.45 20.5594 21.2987C20.6082 21.1244 20.4903 20.8613 20.2545 20.3349L12.766 3.6222C12.5354 3.1075 12.4201 2.85015 12.2594 2.77041C12.1199 2.70113 11.956 2.70087 11.8162 2.7697C11.6553 2.84892 11.5392 3.1059 11.3069 3.61986L3.75244 20.3359C3.51474 20.8619 3.39589 21.1248 3.44422 21.2993C3.48619 21.4508 3.59697 21.5737 3.74329 21.6312C3.91178 21.6974 4.18567 21.6064 4.73346 21.4246L11.786 19.0838C11.8799 19.0527 11.9268 19.0371 11.9749 19.0309C12.0175 19.0255 12.0606 19.0255 12.1032 19.0311C12.1512 19.0374 12.1981 19.0531 12.292 19.0845Z"
                                                stroke="#ffff"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
                {sources.length > 0 && !demo && (
                    <div style={{ marginTop: "2rem" }}>
                        <h1 style={{ fontSize: "2.5rem" }}>Sources</h1>
                        <div
                            className="sources"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "2.5rem",
                                marginTop: "1rem",
                            }}
                        >
                            {sources.map((source, idx) => {
                                return (
                                    <div key={idx} className="source">
                                        <p>{source}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
