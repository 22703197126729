import React from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function PricingComponent() {
    const navigate = useNavigate();
    const authenticated = document.cookie.includes("connect.sid");
    const pathname = window.location.pathname;
    const dark = pathname === "/" ? "dark" : "";

    async function payClicked(type) {
        let priceId;
        if (!authenticated) return navigate("/signup");
        else {
            if (type == "starter")
                priceId = process.env.REACT_APP_STARTER_PRICE_ID;
            else if (type == "professional")
                priceId = process.env.REACT_APP_PROFESSIONAL_PRICE_ID;
            else {
                return toast.error("Unrecognized payment plan.");
            }
            await pay(priceId);
        }
    }

    async function pay(priceId) {
        let body = JSON.stringify({
            priceId: priceId,
        });
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/pay`,
            {
                method: "POST",
                body,
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            }
        );
        let json = await response.json();
        if (json.error) {
            return toast.error(json.error);
        }
        if (json.url) {
            window.location.replace(json.url);
        }
    }

    return (
        <div className={dark}>
            <section className="bg-white dark:bg-zinc-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-44 lg:px-6 lg:p-10 md:p-10 sm:p-10 max-[700px]:pt-0 ">
                    <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12 max-[700px]:mt-28">
                        <h2 className="mb-4 text-7xl tracking-tight font-extrabold text-gray-900 dark:text-white max-[700px]:text-5xl max-[700px]:pt-16">
                            Fair pricing for everyone
                        </h2>
                    </div>
                    <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0 mt-24">
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-slate-950 bg-white rounded-xl border border-gray-100 shadow xl:p-8 dark:text-gray-900 duration-500 hover:scale-105">
                            <h4 className="text-xl font-semibold text-left">
                                Simple
                            </h4>
                            <h3 className="mb-6 mt-6 text-6xl font-bold text-left ">
                                Free
                            </h3>
                            <p className="font-light text-gray-900 sm:text-lg dark:text-gray-900 text-left mb-5">
                                Free plan for everyone who wants to try out the
                                tool.
                            </p>
                            <ul className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        width="2rem"
                                        height="2rem"
                                        stroke="rgb(0, 0 0)"
                                    >
                                        <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
                                    </svg>
                                    <span>
                                        <span className="font-semibold">
                                            30 messages
                                        </span>{" "}
                                        per month.
                                    </span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        width="2rem"
                                        height="2rem"
                                        stroke="rgb(0, 0 0)"
                                    >
                                        <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
                                    </svg>

                                    <span>
                                        <span className="font-semibold">3</span>{" "}
                                        chatbots
                                    </span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        width="2rem"
                                        height="2rem"
                                        stroke="rgb(0, 0 0)"
                                    >
                                        <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
                                    </svg>
                                    <span>
                                        <span className="font-semibold">
                                            1,000,000 characters
                                        </span>{" "}
                                        per chatbot.
                                    </span>
                                </li>
                            </ul>
                            <button
                                onClick={(e) => payClicked("free")}
                                className="relative px-10 py-3 font-medium text-white transition duration-300 bg-slate-900 rounded-md hover:bg-slate-800 ease mt-auto"
                                disabled={authenticated}
                            >
                                <span className="relative">Try for free</span>
                            </button>
                        </div>
                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-slate-950 bg-white rounded-xl border border-gray-100 shadow xl:p-8 dark:text-gray-900 duration-500 hover:scale-105">
                            <h4 className="text-xl font-semibold text-left">
                                Starter
                            </h4>
                            <div className="mb-6 mt-6 text-6xl font-bold text-left flex">
                                <p className="mt-auto">$7</p>
                                <p className="text-base font-normal mt-8">
                                    /month
                                </p>
                            </div>
                            <p className="font-light text-gray-900 sm:text-lg dark:text-gray-900 text-left mb-5">
                                Best for people who frequently need chatbots.
                            </p>
                            <ul className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        width="2rem"
                                        height="2rem"
                                        stroke="rgb(0, 0 0)"
                                    >
                                        <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
                                    </svg>
                                    <span>
                                        <span className="font-semibold">
                                            500 messages
                                        </span>{" "}
                                        per month.
                                    </span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        width="2rem"
                                        height="2rem"
                                        stroke="rgb(0, 0 0)"
                                    >
                                        <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
                                    </svg>

                                    <span>
                                        <span className="font-semibold">5</span>{" "}
                                        chatbots
                                    </span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        width="2rem"
                                        height="2rem"
                                        stroke="rgb(0, 0 0)"
                                    >
                                        <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
                                    </svg>
                                    <span>Custom AI prompt</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        width="2rem"
                                        height="2rem"
                                        stroke="rgb(0, 0 0)"
                                    >
                                        <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
                                    </svg>
                                    <span>
                                        <span className="font-semibold">
                                            1,000,000 characters
                                        </span>{" "}
                                        per chatbot.
                                    </span>
                                </li>
                            </ul>
                            <button
                                onClick={() => payClicked("starter")}
                                className="relative px-10 py-3 font-medium text-white transition duration-300 bg-slate-900 rounded-md hover:bg-slate-800 ease mt-auto"
                            >
                                <span className="relative">Get started</span>
                            </button>
                        </div>

                        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-slate-950 bg-white rounded-xl border border-gray-100 shadow xl:p-8 dark:text-gray-900 duration-500 hover:scale-105">
                            <h4 className="text-xl font-semibold text-left">
                                Professional
                            </h4>
                            <div className="mb-6 mt-6 text-6xl font-bold text-left flex">
                                <p className="mt-auto">$19</p>
                                <p className="text-base font-normal mt-8">
                                    /month
                                </p>
                            </div>
                            <p className="font-light text-gray-900 sm:text-lg dark:text-gray-900 text-left mb-5">
                                Best for professionals who use custom chatbots
                                on a daily basis.
                            </p>
                            <ul className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        width="2rem"
                                        height="2rem"
                                        stroke="rgb(0, 0 0)"
                                    >
                                        <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
                                    </svg>
                                    <span>
                                        <span className="font-semibold">
                                            2000 messages
                                        </span>{" "}
                                        per month.
                                    </span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        width="2rem"
                                        height="2rem"
                                        stroke="rgb(0, 0 0)"
                                    >
                                        <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
                                    </svg>

                                    <span>
                                        <span className="font-semibold">
                                            15
                                        </span>{" "}
                                        chatbots
                                    </span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        width="2rem"
                                        height="2rem"
                                        stroke="rgb(0, 0 0)"
                                    >
                                        <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
                                    </svg>
                                    <span>Custom AI prompt</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        width="2rem"
                                        height="2rem"
                                        stroke="rgb(0, 0 0)"
                                    >
                                        <path d="M32,6C17.641,6,6,17.641,6,32c0,14.359,11.641,26,26,26s26-11.641,26-26C58,17.641,46.359,6,32,6z M29,42L18,31l2-3l9,6 l13.957-12L46,25L29,42z" />
                                    </svg>
                                    <span>
                                        <span className="font-semibold">
                                            2,000,000 characters
                                        </span>{" "}
                                        per chatbot.
                                    </span>
                                </li>
                            </ul>
                            <button
                                onClick={() => payClicked("professional")}
                                className="relative px-10 py-3 font-medium text-white transition duration-300 bg-slate-900 rounded-md hover:bg-slate-800 ease mt-auto"
                            >
                                <span className="relative">Get started</span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
