import React from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function GoogleButton(props) {
    const navigate = useNavigate();

    function getGoogleUrl() {
        const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

        const options = {
            redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            access_type: "offline",
            response_type: "code",
            prompt: "consent",
            scope: [
                "https://www.googleapis.com/auth/userinfo.profile",
                "https://www.googleapis.com/auth/userinfo.email",
            ].join(" "),
        };

        const qs = new URLSearchParams(options);
        const url = `${rootUrl}?${qs.toString()}`;
        return url;
    }

    async function makeOauthCall() {
        let url = getGoogleUrl();
        console.log(url);
        window.location.replace(url);
    }
    const text = props.login ? "Sign in with Google" : "Sign up with Google";
    return (
        <div className="w-full rounded-lg dark:border md:mt-0 max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex justify-center border-2 border-gray-400 mb-8 rounded-lg">
                <button
                    onClick={(e) => makeOauthCall()}
                    className="outline-none text-center bg-white text-gray-900 rounded-lg focus:ring-primary-600 p-2.5 dark:text-white flex justify-center items-center gap-6 text-lg font-semibold"
                >
                    <img
                        style={{ width: "2rem" }}
                        src="https://developers.google.com/identity/images/g-logo.png"
                    />
                    {text}
                </button>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <hr className="hr-google"></hr>
                <p
                    style={{
                        margin: "0 1rem",
                        fontWeight: "700",
                        opacity: "0.35",
                    }}
                >
                    OR
                </p>
                <hr className="hr-google"></hr>
            </div>
        </div>
    );
}
