import logo from "../img/logo.svg";

export default function Header() {
    const authenticated = document.cookie.includes("connect.sid");
    const pathname = window.location.pathname;

    const defaultPageClass =
        "block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700";
    const selectedPageClass =
        defaultPageClass + " lg:text-primary-700 text-zinc-900 font-semibold";
    return (
        <div
            style={{
                position: "fixed",
                width: "100%",
                backgroundColor: "white",
                zIndex: "999",
            }}
        >
            <header style={{ boxShadow: "0 0 5px rgba(40,70,80,0.4)" }}>

                <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">

                    <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl gap-x-0.5">
                    <nav className="nav-phone-item">
                        <div className="navbar">
                            <div className="container nav-container">
                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    name=""
                                    id=""
                                />
                                <div className="hamburger-lines">
                                    <span className="line line1"></span>
                                    <span className="line line2"></span>
                                    <span className="line line3"></span>
                                </div>
                                <div className="menu-items">
                                    <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                                        <li>
                                            <a
                                                href="/"
                                                className="text-zinc-900 font-semibold lg:bg-transparent lg:text-primary-700 block py-2 pr-4 pl-3 lg:p-0 rounded bg-primary-700"
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="/pricing"
                                                className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                                            >
                                                Pricing
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="/faq"
                                                className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                                            >
                                                FAQ
                                            </a>
                                        </li>
                                        {authenticated && (
                                            <li>
                                                <a
                                                    href="/chatbots"
                                                    className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                                                >
                                                    My Chatbots
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                        <a href="/" className="flex items-center">
                            <img
                                src={logo}
                                className="mt-1 mr-1 h-2 sm:h-9 max-[700px]:hidden"
                                alt="pdf2chat Logo"
                            />
                            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white max-[700px]:hidden">
                                pdf2chat
                            </span>
                        </a>
                        {!authenticated && (
                            <div className="flex items-center lg:order-2">
                                <a
                                    href="/login"
                                    className="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                                >
                                    Log in
                                </a>
                                <a
                                    href="/signup"
                                    className="text-white bg-blue-600 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                >
                                    Get started
                                </a>
                            </div>
                        )}
                        {/* hujovas fixas, reiketu kad kitaip bsk kodas sudeliotas butu */}
                        {authenticated && (
                            <div className="flex items-center lg:order-2 max-[700px]:mr-0">
                                <a
                                    href="/account"
                                    className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                >
                                    Account
                                </a>
                            </div>
                        )}

                        <div
                            className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
                            id="mobile-menu-2"
                        >
                            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                                {/* lg:text-primary-700 text-zinc-900 font-semibold */}
                                <li>
                                    <a
                                        href="/"
                                        className={
                                            pathname === "/"
                                                ? selectedPageClass
                                                : defaultPageClass
                                        }
                                    >
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/pricing"
                                        className={
                                            pathname === "/pricing"
                                                ? selectedPageClass
                                                : defaultPageClass
                                        }
                                    >
                                        Pricing
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/faq"
                                        className={
                                            pathname === "/faq"
                                                ? selectedPageClass
                                                : defaultPageClass
                                        }
                                    >
                                        FAQ
                                    </a>
                                </li>
                                {authenticated && (
                                    <li>
                                        <a
                                            href="/chatbots"
                                            className={
                                                pathname === "/chatbots"
                                                    ? selectedPageClass
                                                    : defaultPageClass
                                            }
                                        >
                                            My Chatbots
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    );
}
